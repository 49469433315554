import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { useQuery, useQueryClient } from "@tanstack/react-query";
import apiServices from "services/api/campaign.js";
import _ from "lodash";
import { DatePicker } from "@material-ui/pickers";
import { format, isValid } from "date-fns";
import { useToggle } from "utils/constants/hooks";
import copy from "copy-to-clipboard";
import SnackbarWrapper from "components/Molecules/Snackbar/Snackbar";



const { getFieldOptionsForUtmCampaignTypesFromDirectus, saveCampaignDetails } = apiServices;


// import { auth } from "../../components/firebase";

// 5CpXNyunbbGnnEx8cXAUPiPF9uj_Lh8H

const Container = styled.div`
  width: 100%;
  position: relative;
  // border: 4px solid red;
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const AutoCompleteWithFormik = ({name="", label="", options=[], nameKey="", codeKey="", freeSolo=false, formik={}, disabled=false, groupBy=null}) => {
  return (
    <Autocomplete
      id={name}
      name={name}
      options={options}
      disabled={disabled}
      filterOptions={(options, state)=>{
        let newOptions = [];
        options.forEach((element) => {
          if (
            element[nameKey]
              .replace(",", "")
              .toLowerCase()
              .includes(state.inputValue.toLowerCase())
          )
            newOptions.push(element);
        });
        return newOptions;
      }}
      getOptionLabel={(option) => {
        const selectedOption = options.find(x => _.get(x, codeKey)===_.get(formik.values, name));
        if(selectedOption){
          return `${_.get(selectedOption, nameKey)}(${_.get(selectedOption, codeKey)})`
        }else{
          return "";
        }
      }}
      renderOption={(option) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <span>{_.get(option, nameKey)}</span>
          <span>{_.get(option, codeKey)}</span>
        </div>
      )}
      {...(_.isEmpty(groupBy) ? {}: {groupBy: o => o[groupBy]})}
      value={formik.values[name]} freeSolo={freeSolo}
      getOptionSelected={(option, value) => _.get(option, codeKey)===value}
      onChange={(e, value) => {formik.setFieldTouched(name, true); formik.setFieldValue(name, _.get(value, codeKey));}}
      renderInput={(params) => <TextField label={label} error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]} {...params} fullWidth variant="outlined" />}
    />
  );
};

const getDateStr = (date) => {
  if(isValid(date)){
    return format(date, "ddMMyyyy");
  }else{
    return "";
  }
};

const additionalFields = {
  "paid": [
    {name: "hub", label: "Hub", optionsPath: "utm_campaign_hub", schema: yup.string("Select Hub").nullable().required("Hub is required")}
  ],
  "organic": [
    {name: "hub", label: "Hub", optionsPath: "utm_campaign_hub", schema: yup.string("Select Hub").nullable().required("Hub is required")}
  ],
  "internal": [
    {name: "category", label: "Category", optionsPath: "utm_campaign_categories", schema: yup.string("Select Category").nullable().required("Category is required")},
    {name: "channelCode", label: "Channel Code", optionsPath: "channel_codes", schema: yup.string("Select Channel Code").nullable().required("Channel Code is required")}
  ],
  "email": [
    {name: "hub", label: "Hub", optionsPath: "utm_campaign_hub", schema: yup.string("Select Hub").nullable().required("Hub is required")},
    {name: "category", label: "Category", optionsPath: "utm_campaign_categories", schema: yup.string("Select Category").nullable().required("Category is required")},
  ],
  "programmatic": [
    {name: "hub", label: "Hub", optionsPath: "utm_campaign_hub", schema: yup.string("Select Hub").nullable().required("Hub is required")},
    {name: "publisher", label: "Publisher", optionsPath: "channel_codes", freeSolo: true, schema: yup.string("Select Publisher").nullable().required("Publisher is required")},
  ]
};

const getValidationSchema = (campaignTypeCode="paid") => {
  const temp = yup.object({
    advertiser: yup.string("Enter Advertiser").nullable().required("Advertiser is required"),
    country: yup.string("Select Country").nullable().required("Country is required"),
    type: yup.string("Select type").nullable().required("Type is required"),
    strategyType: yup.string("Select Strategy Type").nullable().required("Strategy Type is required"),
    campaignName: yup
      .string("Enter Campaign Name")
      .required("Campaign Name is required"),
    startDate: yup.date("Select Start Date").nullable().required("Start Date is required"),
    endDate: yup.date("Select End Date").nullable().required("End Date is required"),
    cultureCode: yup
      .string("Select Culture Code").nullable()
      .required("Culture Code is required"),
    ...(_.get(additionalFields, campaignTypeCode, []).reduce((acc, x) => {acc[x.name]= x.schema; return acc}, {}))
  });
  console.log("getValidationSchema --- ", temp);
  return temp;
}

const CampaignForm = ({campaignType="", campaignTypeCode="paid", userEmail=""}) => {

  const queryClient = useQueryClient();

  const {data:fieldOptions={}, status} = useQuery(["campaign-types", campaignType], ()=>getFieldOptionsForUtmCampaignTypesFromDirectus(campaignType));
  const [isLoading, toggleIsLoading] = useToggle(false);
  const [finalCampaignName, setFinalCampaignName] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  useEffect(()=>{
    if(finalCampaignName){
      setIsCompleted(true);
    }
  }, [finalCampaignName])

  const handleGenerate = async (values) => {
    toggleIsLoading();
    const {advertiser, country, type, strategyType, campaignName, startDate, endDate, cultureCode, hub="None", category="None", channelCode="None", publisher="None"} = values;
    let formedName = "";
    switch (campaignTypeCode) {
      case "paid": {
        formedName = `${advertiser}_${country}_${type}|${strategyType}|${campaignName}|${getDateStr(startDate)}|${getDateStr(endDate)}|${cultureCode}|${hub}`;
        break;
      }
      case "organic": {
        formedName = `${advertiser}_${country}_${type}|${strategyType}|${campaignName}|${getDateStr(startDate)}|${getDateStr(endDate)}|${cultureCode}|${hub}`;
        break;
      }
      case "internal": {
        formedName = `${advertiser}_${country}_${type}|${strategyType}|${campaignName}|${getDateStr(startDate)}|${getDateStr(endDate)}|${cultureCode}|${channelCode}|${category}`;
        break;
      }
      case "email": {
        formedName = `${advertiser}_${country}_${type}|${strategyType}|${campaignName}|${getDateStr(startDate)}|${getDateStr(endDate)}|${cultureCode}|${hub}|${category}`;
        break;
      }
      case "programmatic": {
        formedName = `${advertiser}_${country}_${type}|${strategyType}|${campaignName}|${getDateStr(startDate)}|${getDateStr(endDate)}|${cultureCode}|${hub}|${publisher}`;
        break;
      }
      default: {
        formedName = `${advertiser}_${country}_${type}|${strategyType}|${campaignName}|${getDateStr(startDate)}|${getDateStr(endDate)}|${cultureCode}|${hub}`;
        break;
      }
    }

    const reqPayload = {
      name: formedName, user: userEmail, startDate: format(startDate, "yyyy-MM-dd"), endDate: format(endDate, "yyyy-MM-dd")
    };
    await saveCampaignDetails(reqPayload).then(resp => {
      console.log("resp from save -- ", resp);
      toggleIsLoading();
      setFinalCampaignName(_.get(resp, "name"));
      queryClient.invalidateQueries(['campaigns-list'])
    }).catch(err => {
      console.error("Error from save --- ", err);
      toggleIsLoading();
    });
  };

  console.log("fieldOptions -- ", fieldOptions);
  const {campaign_advertisers: advertisers=[], countries=[], languages=[], utm_campaign_display_type: displayTypes=[], utm_campaign_strategy_type: strategyTypes=[], utm_campaign_hub: hubs=[]  } = fieldOptions || {};

  const validationSchema = yup.object({
    advertiser: yup.string("Enter Advertiser").nullable().required("Advertiser is required"),
    country: yup.string("Select Country").nullable().required("Country is required"),
    type: yup.string("Select type").nullable().required("Type is required"),
    strategyType: yup.string("Select Strategy Type").nullable().required("Strategy Type is required"),
    campaignName: yup
      .string("Enter Campaign Name")
      .required("Campaign Name is required"),
    startDate: yup.date("Select Start Date").nullable().required("Start Date is required"),
    endDate: yup.date("Select End Date").nullable().required("End Date is required"),
    cultureCode: yup
      .string("Select Culture Code").nullable()
      .required("Culture Code is required"),
    ...(_.get(additionalFields, campaignTypeCode, []).map(x => ({[x.name]: x.schema})))
  });

  const formik = useFormik({
    initialValues: {endDate: null, startDate: null, advertiser: null, country: null, type: null, strategyType: null, campaignName: "", cultureCode: null, ...(_.get(additionalFields, campaignTypeCode, []).reduce((acc, x) => {acc[x.name]= null; return acc}, {}))},
    validationSchema: getValidationSchema(campaignTypeCode),
    onSubmit: (values) => {
      console.log("values -- ", values);
      handleGenerate(values);
    },
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="campaignName"
              name="campaignName"
              label="Campaign Name"
              value={formik.values.campaignName}
              disabled={isLoading || isCompleted}
              onChange={formik.handleChange}
              error={formik.touched.campaignName && Boolean(formik.errors.campaignName)}
              helperText={formik.touched.campaignName && formik.errors.campaignName}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <AutoCompleteWithFormik disabled={isLoading || isCompleted} name="strategyType" label="Strategy Type" formik={formik} options={strategyTypes} nameKey="name" codeKey="code" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteWithFormik disabled={isLoading || isCompleted} name="advertiser" label="Advertiser" formik={formik} options={advertisers} nameKey="name" codeKey="code" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteWithFormik disabled={isLoading || isCompleted} name="type" label="Type" formik={formik} options={displayTypes} nameKey="name" codeKey="code" groupBy="group" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteWithFormik disabled={isLoading || isCompleted} name="country" label="Country" formik={formik} options={countries} nameKey="name" codeKey="code" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteWithFormik disabled={isLoading || isCompleted} name="cultureCode" label="Culture Code" formik={formik} options={languages} nameKey="name" codeKey="code" />
          </Grid>
          
          {
            _.get(additionalFields, campaignTypeCode, []).map(x => (
              <Grid item xs={12} sm={12} key={`additionalFields_${x["name"]}`}>
                <AutoCompleteWithFormik disabled={isLoading || isCompleted} name={x["name"]} label={x["label"]} freeSolo={x?.freeSolo || false} formik={formik} options={_.get(fieldOptions, x["optionsPath"], [])} nameKey="name" codeKey="code" />
              </Grid>
            ))
          }
          {/* <Grid item xs={12} sm={6}>
            <AutoCompleteWithFormik disabled={isLoading || isCompleted} name="hub" label="Hub" formik={formik} options={hubs} nameKey="name" codeKey="code" />
          </Grid> */}

          <Grid item xs={12} sm={12}>
            <DatePicker
              openTo="date"
              format="dd/MM/yyyy"
              label="Start Date"
              name="startDate"
              id="startDate"
              disabled={isLoading || isCompleted}
              inputVariant="outlined"
              // variant="inline"
              views={["year", "month", "date"]}
              value={formik.values.startDate}
              onChange={(date) => {formik.setFieldTouched("startDate", true); formik.setFieldValue("startDate", date); }}
              TextFieldComponent={(compProps) => <TextField {...compProps}
              error={formik.touched.startDate && Boolean(formik.errors.startDate)}
              helperText={formik.touched.startDate && formik.errors.startDate}
              fullWidth variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <DatePicker
              openTo="date"
              format="dd/MM/yyyy"
              label="End Date"
              name="endDate"
              id="endDate"
              disabled={isLoading || isCompleted}
              inputVariant="outlined"
              // variant="inline"
              views={["year", "month", "date"]}
              value={formik.values.endDate}
              onChange={(date) => {formik.setFieldTouched("endDate", true); formik.setFieldValue("endDate", date); }}
              TextFieldComponent={(compProps) => <TextField {...compProps}
              error={formik.touched.endDate && Boolean(formik.errors.endDate)}
              helperText={formik.touched.endDate && formik.errors.endDate}
              fullWidth variant="outlined" />}
            />
          </Grid>

          {isCompleted ? <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="finalCampaignName"
              name="finalCampaignName"
              label="Final Campaign Name"
              value={finalCampaignName}
              InputLabelProps={{shrink: true}}
            />
          </Grid> : null}


          {/* <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              // id="campaignName"
              // name="campaignName"
              label="Final Campaign Name"
            // value={formik.values.campaignName}
            // onChange={formik.handleChange}
            // error={formik.touched.campaignName && Boolean(formik.errors.campaignName)}
            // helperText={formik.touched.campaignName && formik.errors.campaignName}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Button color="primary" variant="contained" disabled={isLoading} {...(isCompleted ? {style:{background:"olivegreen"}, onClick: ()=>{copy(finalCampaignName); setSnackMessage("Copied to Clipboard!")}} : {})} fullWidth type={isCompleted ? "button" : "submit"} size="large" disableElevation>
              {isCompleted ? "Copy to Clipboard" : "Generate Campaign Name"}
            </Button>
          </Grid>
        </Grid>

      </form>
      <SnackbarWrapper show={!_.isEmpty(snackMessage)} onClose={()=>setSnackMessage("")} alertMessage={snackMessage}  />
    </Container>
  );
};

export default CampaignForm;
