/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
// core components
import styles from 'assets/jss/material-dashboard-react/components/footerStyle.js';

const useStyles = makeStyles(styles);

export default function Footer(props) {
	console.log(``);
	console.log(``);

	const classes = useStyles();
	return (
		<footer className={classes.footer}>
			<div className={classes.container}>
				<div className={classes.left} />
				<p className={classes.right}>
					<span>
						&copy; {1900 + new Date().getYear()}{' '}
						Built by <a href="https://airasia.com" target="_blank" className={classes.a}>
							airasia
						</a> Growth Team
					</span>
				</p>
			</div>
		</footer>
	);
}
