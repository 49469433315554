import React, { useEffect, useState, useContext } from "react";
import { uiConfig, auth } from "components/firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import logo from "assets/img/logo_new.png";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { Typography } from "@material-ui/core";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import axios from "axios";
import md5 from "md5";
import "./login.css";
import styled from "styled-components";
import { SubdirectoryArrowLeft } from "@material-ui/icons";
import auth_service from "services/api/authentication";
import SSOLogin from "components/SsoLogin";
import { UserContext } from "UserContext";
// import SSOLogin from "@airasia/phoenix-widgets/widgets/Login";

function pxToRem(value) {
  return `${value / 16}rem`;
}
const breakpoints = createBreakpoints({});
const styles = {};
const ThemeStyle = {
  breakpoints,
  overrides: {
    MuiTypography: {
      headline: {
        fontSize: pxToRem(24),
        [breakpoints.up("md")]: {
          fontSize: pxToRem(12),
        },
      },
      title: {
        fontSize: pxToRem(21),
        [breakpoints.up("md")]: {
          fontSize: pxToRem(12),
        },
      },
    },
  },
};
const useStyles = makeStyles(styles);
let theme = createMuiTheme(ThemeStyle);

const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});
const Images = importAll(
  require.context("assets/img/landing", false, /\.(png|jpe?g|svg)$/)
);
const LoginContainer = styled.div`
  background-image: url(${Images["login-background.png"]});
  min-height: 100vh;
  place-content: center;
  place-items: center;
  display: flex;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;
const WhiteNormalFont = `
    font-family: "Dm Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #FFFFFF;
`;
const Title = styled.h2`
  ${WhiteNormalFont}
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;
const Subtitle = styled.div`
  ${WhiteNormalFont}
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;
const CardTitle = styled.div`
  ${WhiteNormalFont}
  font-size: 20px;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;
const Logo = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 12px;
  @media (max-width: 480px) {
    max-width: 120px;
    height: 40px;
    margin-top: 48px;
  }
`;
const Login = () => {
  const { user = {} } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    var location = window.sessionStorage.getItem("redirect");
    location = location ? location : "/admin/dashboard";
    console.log("redirect", location);

    if (_.has(user, "userInfo.username")) {
      history.push(location);
    }
  }, [user]);
  return (
    <MuiThemeProvider theme={theme}>
      <LoginContainer className="login">
        <div className="login__container">
          <div className="login__logo">
            <Logo src={Images["aab-logo-white.png"]} alt="logo" />
          </div>
          <div className="login__content">
            <Title>Dear Allstar, Welcome to Ambassador Program</Title>
            <Subtitle>Promote airasia products and Earn up to 5% </Subtitle>
            <hr />
            <Card
              style={{
                padding: "48px 5%",
                backgroundColor: "#C92D21",
                width: "90%",
              }}
            >
              <CardHeader style={{ textAlign: "center" }}>
                <CardTitle>Sign in with your AirAsia email to join </CardTitle>
              </CardHeader>
              <SSOLogin />
            </Card>
          </div>
        </div>
      </LoginContainer>
    </MuiThemeProvider>
  );
};

export default Login;
