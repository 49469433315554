import React, { createContext, useEffect, useReducer } from "react";
import _ from "lodash";
export const UserContext = createContext();

const initialState = {};

const getInitialState = () => {
    let temp = window.localStorage.getItem("aaWidget_userInfo");
    let tempJson = JSON.parse(temp);
    if(_.isPlainObject(tempJson)){
        return tempJson;
    }else{
        return {}
    }
};

const actions = {
  UPDATE_USER: "UPDATE_USER",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.UPDATE_USER:
      return _.cloneDeep(_.get(action, "payload", {}));
    default:
      return state;
  }
};

const UserCtxProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const value = {
    user: state,
    updateUser: (payload) => {
      let isAllstar = /(@airasia\.com)$/gi.test(
        _.get(payload, "userInfo.username", "")
      );
      dispatch({
        type: actions.UPDATE_USER,
        payload: { ...payload, isAllstar },
      });
    },
  };

  useEffect(() => {
    window.localStorage.setItem("aaWidget_userInfo", JSON.stringify(state));
  }, [state]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserCtxProvider;
